.majors_LIST{

    display: flex;
    flex-direction: row;
    width: 80%;
    flex-wrap: wrap;
    gap: 4rem;
    justify-content: center;
    margin-bottom: 4rem;
    margin-top: 2rem;
}
.fixed-button {
    position: fixed;
    bottom: 20px; /* Adjust this value as per your requirement */
    right: 20px; /* Adjust this value as per your requirement */
  
    color: white;
    cursor: pointer;
  }
  .fixed-buttonar {
    position: fixed;
    bottom: 20px; /* Adjust this value as per your requirement */
    left: 20px; /* Adjust this value as per your requirement */
  
    color: white;
    cursor: pointer;
  }
.Title-map1{
    font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold) var(--unnamed-font-size-72)/var(--unnamed-line-spacing-78) var(--unnamed-font-family-tajawal);
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-ffffff);
    text-align: center;
    font: normal normal bold 4vw regular;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    z-index: 10;
    position: absolute;
 left: 37%;
 margin-top: 4%;
}
