.container-first {
  display: flex;
  flex-direction: column;
  background-color: var(--grey);

  margin-top: 5rem;
}
.header-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 10rem;
  justify-content: flex-start;

  margin-left: 5rem;
}
.back-major {
  margin-top: 1rem;
  margin-left: 2rem;
}
.back-majorar {
  margin-top: 1rem;
  transform: rotate(180deg);
  cursor: pointer;
  margin-right: 2rem;
}
.title-name {
  font-size: 2rem;
  background-color: white;
  padding: 3px;
  font-family: var(--bold);
  font-weight: 5rem;
  color: var(--navy);
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: 10px 7px 11px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 10px 7px 11px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 10px 7px 11px 0px rgba(0, 0, 0, 0.15);
}
.title-name_ar {
  font-size: 2rem;
  background-color: white;
  padding: 3px;
  font-family: var(--bold);
  font-weight: 5rem;
  color: var(--navy);
  border-radius: 5px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 3rem;
  box-shadow: 10px 7px 11px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 10px 7px 11px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 10px 7px 11px 0px rgba(0, 0, 0, 0.15);
}
.second-containerss {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-left: 6rem;
  margin-bottom: 2rem;
  gap: 4rem;
}
.fisrtinsecond {
  display: flex;
  flex-direction: column;

  gap: 0.9rem;
}
.fisrtinsecond_ar {
  display: flex;
  flex-direction: column;

  margin-right: 6rem;

  gap: 0.9rem;
}
.title-content {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-32) / var(--unnamed-line-spacing-16)
    var(--unnamed-font-family-tajawal);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-171546);
  text-align: left;
  font: normal normal bold 1.6rem var(--bold);
  letter-spacing: 0px;
  color: #171546;
  opacity: 1;
  display: flex;
  justify-content: flex-start;
}
.elemntinc {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-32) / var(--unnamed-line-spacing-16)
    var(--unnamed-font-family-tajawal);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-171546);
  text-align: left;
  font: normal normal bold 1rem var(--medium);
  letter-spacing: 0px;
  color: #171546;
  opacity: 1;
  padding-bottom: 10px;
  border-bottom: solid rgba(71, 71, 71, 0.641) 1px;
  display: flex;
  justify-content: flex-start;
}
.secondinsecond {
  border-left: solid rgba(71, 71, 71, 0.641) 1px;
  display: flex;
  flex-direction: column;
  width: 40rem;
  gap: 2rem;
}
.secondinsecond_ar {
  border-right: solid rgba(71, 71, 71, 0.641) 1px;
  display: flex;
  flex-direction: column;
  width: 40rem;
  gap: 2rem;
  padding-right: 4rem;
}
.first--row {
  display: flex;
  flex-direction: row;
  gap: 4rem;
  flex-wrap: wrap;
  margin-left: 3rem;
}
.firstelemnt-row {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-32) / var(--unnamed-line-spacing-16)
    var(--unnamed-font-family-tajawal);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-888888);
  text-align: left;
  font: normal normal bold 1.2rem var(--medium);
  letter-spacing: 0px;
  color: #888888;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.paragraph-title {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    48px / var(--unnamed-line-spacing-16) var(--unnamed-font-family-tajawal);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-171546);
  text-align: left;
  font: normal normal bold 1.5rem var(--medium);
  letter-spacing: 0px;
  color: #171546;
  opacity: 1;
  margin-left: 3rem;
  justify-content: flex-start;
  display: flex;
}
.paragraph-description {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-32) / 60px
    var(--unnamed-font-family-tajawal);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-888888);
  text-align: left;
  font: normal normal normal 1.2rem/2rem var(--medium);
  letter-spacing: 0px;
  color: #888888;
  opacity: 1;
  margin-left: 3rem;
  justify-content: flex-start;
  display: flex;
  text-align: start;
}
.icon {
  margin-top: 3px;
}
a {
  text-decoration: none;
  color: #171546;
}
.major-show-next {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: 3rem;
  gap: 2rem;
}
@media screen and (max-width: 480px) {
  .title-name {
    margin-left: -5rem;
  }
  .second-containerss {
    margin-left: 0rem;
  }
  .first--row {
    gap: 1rem;
    margin-left: 1.4rem;
  }
  .fisrtinsecond {
    margin-left: 3rem;
  }

  .back-major {
    margin-left: 0rem;
  }
  .back-majorar {
    margin-right: 0rem;
  }
}
.arrowww {
  background-color: var(--navy);
  border-radius: 3px;
  cursor: pointer;
}
