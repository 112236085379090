.App {
  text-align: center;

  
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: "regular";
  src: url(./assets/Fonts/Tajawal-Regular.ttf);
  /* src: url(../font/OpenSans-Regular.ttf); */
}

@font-face {
  font-family: "bold";
  src: url(./assets/Fonts/Tajawal-Bold.ttf);
  /* src: url(../font/OpenSans-Bold.ttf); */
}

@font-face {
  font-family: "medium";
  src: url(./assets/Fonts/Tajawal-Medium.ttf);
  /* src: url(../font/OpenSans-Bold.ttf); */
}
a,Link{
  cursor: pointer;
}

:root {
  --green: #A2BF83;
  --white: #fff;
  --black: #1C1C1C;
  --disabled: #858585;
  --pink: #F14055;
  --danger: #eb3232;
  --border: #d9d9d9;
  --purple: #5C39BA;
  --orange: #fac360;
  --yellow: #FBC846;
  --dark-orange: #ee7f1d;
  --cyan: #1fa79d;
  --grey: #e6e6e6;
  --navy: #171546;
  --dark-grey: #cac6c6;
  --darker-grey: #504e4e;
  --light-blue: #e1f7fa;
  --medium-blue: #6d8acc;
  --darker-blue: #242E6C;
  --dark-blue: #1fa8a0;
  --bottom-grey: #707070;
  --bold: "bold";
  --regular: "regular";
  --medium: "medium";
  --light: 200;

  --px-50: 50px;
  --px-40: 40px;
  --px-30: 30px;
  --px-25: 25px;
  --px-20: 20px;
  --px-18: 18px;
  --px-16: 16px;
  --px-14: 14px;
  --px-12: 12px;
  --px-10: 10px;

  --shadow: 5px 5px 5px rgba(0, 0, 0, 0.3) !important;
}

.body {
  font-family: var(--regular);

}
