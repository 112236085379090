.Main_Burger_div {
  transition: all 2s ease-in-out;
  height: 100vh;
  width: 36%;
  background: #f8f8f8;
  position: fixed;
  z-index: 22;
  top: 0;
  right: 0;

  bottom: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0px 0px 5px rgb(0 0 0 / 20%) !important;
  overflow: scroll;
}

.close_burger {
  cursor: pointer;
  display: flex;
}

.Burger_nav_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
  cursor: pointer;
}

.divider {
  border-bottom: dotted 5px var(--grey);
  margin: 10px 0;
}

.Burger_nav_item_text {
  font-family: var(--bold);
  font-size: 1.2rem;
  color: var(--navy);
  white-space: nowrap;
  cursor: pointer;
}

.profile_image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.Burger_logedin_container {
  margin-top: 1rem;
  cursor: pointer;
}

.Burger_logedin_text {
  opacity: 0.5;
  margin-top: 5px;
}

/* .Main_Burger_div {

    height: 20vh;
    width: 100%;
    background: #0b1b46;
    position: fixed;
    z-index: 22;
    top: 12%;
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0px 0px 5px rgb(0 0 0 / 20%) !important;
    transition: transform 0.5s ease-in-out;
    transform: translateY(0);



} */
