.card-container {
  display: flex;
  flex-direction: column;
  border: solid 1px rgba(79, 79, 79, 0.676);
  width: 16rem;
  height: 17rem;
  border-radius: 10px;
  justify-content: center;
  /* align-items: center; */
  gap: 3rem;
  background-color: white;

  cursor: pointer;
}
.card-container:hover {
  box-shadow: -1px 5px 29px 11px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: -1px 5px 29px 11px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: -1px 5px 29px 11px rgba(0, 0, 0, 0.17);

  cursor: pointer;
}
.major {
}
.propstitle {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    48px / var(--unnamed-line-spacing-78) var(--unnamed-font-family-tajawal);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-171546);
  text-align: center;
  font: normal normal bold 1.5rem regular;
  letter-spacing: 0px;
  color: #171546;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.major {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    var(--unnamed-font-size-28) / var(--unnamed-line-spacing-40)
    var(--unnamed-font-family-tajawal);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: center;
  font: normal normal bold 1rem bold;
  letter-spacing: 0px;
  color: #ffffff;

  opacity: 1;

  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-left: 1rem;
}
.itemss {
  background-color: #171546;
  border-radius: 2px;
  padding: 7px;
}
