.single-page {
  display: flex;
  flex-direction: column;
  margin-top: 5vw;
  background-color: #eaebeb;
  margin-bottom: 10rem;
}
.profile {
  width: 15rem;

  z-index: 10;
  margin-top: 6rem;
  margin-left: 7rem;
}

.fist-image {
  width: 100%;

  height: 17rem;
  position: absolute;
  z-index: 0;
}
.img-profile {
  z-index: 10;
  width: 15rem;
  height: 15rem;
  border-radius: 10px;
  position: absolute;
  margin-left: 6.5rem;
  margin-top: 5.5rem;
  border-radius: 13rem;
}
.img-profile_ar {
  z-index: 10;
  width: 15rem;
  height: 15rem;
  border-radius: 10px;
  position: absolute;
  margin-left: 6.5rem;
  margin-top: 5.5rem;
  border-radius: 13rem;
}
.bothpic {
  display: flex;
}
.title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 6rem;
}
.title-first {
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-bold)
    20px / var(--unnamed-line-spacing-32) var(--unnamed-font-family-tajawal);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-171546);
  text-align: left;
  font: normal normal bold 35px/32px medium;
  letter-spacing: 0px;
  color: #171546;
  opacity: 1;
  margin-top: 4rem;
  display: flex;
}
.linked {
  object-fit: contain;
  width: 2rem;
}
.set-linked {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-right: 8rem;
}
.set-linkedar {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-left: 8rem;
}
.setofdetails {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 4rem;

  flex-wrap: wrap;
}
.datials {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.item {
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-35) /
    var(--unnamed-line-spacing-25) var(--unnamed-font-family-tajawal);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-888888);
  text-align: left;
  font: normal normal normal 23px/25px Tajawal;
  letter-spacing: 0px;
  color: #888888 !important;
  opacity: 1;
  display: flex;
  flex-direction: row;
}
.secondcont {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-left: 10%;
  flex-wrap: wrap;
}
.secondcontar {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-right: 10%;
  flex-wrap: wrap;
}
.description {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-888888);
  text-align: left;
  font: normal normal normal 25px/40px Regular;
  letter-spacing: 0px;
  color: #888888;
  opacity: 1;
  width: 70%;
  display: flex;
}
.majors {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.viwmorebutton {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.blue-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.blue-loader {
  border: 8px solid #3498db; /* Blue color */
  border-top: 8px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.action-two {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1rem;
}
.seeall {
  color: var(--unnamed-color-171546);

  font: normal normal bold 22px/30px regular;
  letter-spacing: 0px;
  color: #171546;
  opacity: 1;
}
.Map_container {
  width: 80%;
  border: solid 10px rgb(255, 255, 255);
  margin-bottom: 5rem;
}
.gallery {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  flex-wrap: wrap;
}
.image-item {
  width: 17rem;
  height: 17rem;
}
@media only screen and (max-width: 1000px) {
  .profile {
    width: 15rem;
    margin-left: 0rem;
    margin-top: 7rem;
  }
  .img-profile {
    margin-left: 0rem;
    margin-top: 7rem;
  }
}
@media only screen and (max-width: 900px) {
  .setofdetails {
    flex-direction: column;
    gap: 1rem;
  }
  .datials {
    gap: 1rem;
  }
  .title {
    gap: 3rem;
  }
  .description {
    width: 90%;
    text-align: justify;
  }
  .majors {
  }
}
